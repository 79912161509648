main{
   
    margin: 0%;
}

h1{
    font-size: 1.8rem;
    padding: 1rem 0;
}
h2{
    font-size: 1.4rem;
    padding: 0.5rem 0;
}
h3{
    padding: 1rem 0;
}

.bmi span{
    margin: 0.1rem;
}

#root{
    height: 100vh;

  
}

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.leaflet-container{
    height: 400px;
    width: 100%;
}

.leaflet-container#mapreport {
    height: 79vh;
    width: 100%;

}

.leaflet-container#mapbrgyflood {
    height: 40vh;
    width: 100%;

}
.leaflet-container#mapbrgylandslide {
    height: 40vh;
    width: 100%;

}
.leaflet-container#mapbrgystormsurge {
    height: 40vh;
    width: 100%;

}

.leaflet-container#mapMuni {
    height: 84vh;
    width: 100%;
}

.no-background {
    background: none;
    box-shadow: none;
    border: none;
  }

  /* custom-accordion.css */

/* Override Accordion Header styles */
.accordion-header {
    background-color: #f0f0f0; /* Custom background color */
    color: #333; /* Custom text color */
    padding: 10px; /* Custom padding */
  }
  
  /* Override Accordion Body styles */
  .accordion-body {
    background-color: #fff; /* Custom background color */
    color: #555; /* Custom text color */
    padding: 15px; /* Custom padding */
  }

  @media screen and (max-width: 500px)
  {
    #logo-adn
    {
        height: 40px !important;
        margin-top: 0px !important;
    }
  }

/* @media print {
    #printsw {
        overflow: hidden !important;
        height:fit-content !important;
    }
    #header {
      visibility: hidden;
      height:0 !important;
      margin-top: -100 !important;
    }
  
    #pds-page-request-result,
    #pds-page-request-result * {
      visibility: visible;
    }
  
    #pds-page-request-result {
      position: absolute;
      left: 0;
      top: 0;
    }
   
    #left-panel  {
        visibility: hidden;
    }
    #home-panel * {
        visibility: hidden;
    }
    #content{
        visibility: hidden;
    }
    #printareac1 {
        top: 0 !important;
        margin-top: -650px !important;
        position: absolute;
        z-index: 100;
        width: 100%;
    }

    .page-footer {
        display: block; 
        position: fixed;
        bottom: 0;
        left: 0;
        width: 200%;
        text-align: center;
        padding: 10px;
        overflow:visible;
        font-style: italic;
        font-size: xx-small;
      }
    
  } */

